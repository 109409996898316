/* eslint-disable react/style-prop-object */
import React from "react"
import Image from 'gatsby-image';
import { Link } from "gatsby";

import Content from './Content';

import 'styles/VodCastItem.scss';

export default function VodCastsItems({ fields, description }) {

  return (
    <section className="vodcasts">
      <div className="container">
        <div className="row">
          <Content className="p-4" content={description} />
        </div>
        <div className="row">
          {fields.edges.map(({ node }) => (
            <Link to={node.path} key={node.id} className="col-12 col-md-6 col-lg-4 col">
              <div className="vodcast">
                <Link to={node.path}>
                  <Image fluid={node.acf.image.localFile.childImageSharp.fluid} alt={node.acf.title} />
                  <div className="length">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_540_173)">
                        <path d="M7 0C5.61553 0 4.26216 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32122C0.003033 5.6003 -0.13559 7.00777 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C13.998 5.1441 13.2599 3.36479 11.9475 2.05247C10.6352 0.74015 8.8559 0.0020073 7 0V0ZM7 12.8333C5.84628 12.8333 4.71846 12.4912 3.75918 11.8502C2.79989 11.2093 2.05222 10.2982 1.61071 9.23232C1.16919 8.16642 1.05368 6.99353 1.27876 5.86197C1.50384 4.73042 2.05941 3.69102 2.87521 2.87521C3.69102 2.0594 4.73042 1.50383 5.86198 1.27875C6.99353 1.05367 8.16642 1.16919 9.23232 1.6107C10.2982 2.05221 11.2093 2.79989 11.8502 3.75917C12.4912 4.71846 12.8333 5.84628 12.8333 7C12.8316 8.54658 12.2165 10.0293 11.1229 11.1229C10.0293 12.2165 8.54658 12.8316 7 12.8333Z" fill="#FEFEFE" />
                        <path d="M6.99991 3.5C6.8452 3.5 6.69683 3.56146 6.58743 3.67085C6.47804 3.78025 6.41658 3.92862 6.41658 4.08333V6.60625L4.45016 7.83824C4.31866 7.92039 4.22518 8.05142 4.19028 8.2025C4.15538 8.35357 4.18193 8.51232 4.26408 8.64383C4.34623 8.77533 4.47726 8.86881 4.62833 8.90371C4.77941 8.93861 4.93816 8.91206 5.06966 8.82991L7.30966 7.42991C7.3943 7.37688 7.4639 7.30302 7.5118 7.21538C7.5597 7.12775 7.5843 7.02928 7.58325 6.92941V4.08333C7.58325 3.92862 7.52179 3.78025 7.41239 3.67085C7.303 3.56146 7.15462 3.5 6.99991 3.5Z" fill="#FEFEFE" />
                      </g>
                      <defs>
                        <clipPath id="clip0_540_173">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ml-2">{node.acf.length_vodcast}</span>
                  </div>
                  <div className="play">
                    <svg id="Laag_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                      <circle cx="50" cy="50" r="48.5" style={{ fill: 'none', stroke: '#fff', strokeWidth: '3px' }} />
                      <path d="m71.53,45.67c3.33,1.92,3.33,6.74,0,8.66l-29.25,16.89c-3.33,1.92-7.5-.48-7.5-4.33v-33.77c0-3.85,4.17-6.25,7.5-4.33l29.25,16.89Z" style={{ fill: '#fff' }} />
                    </svg>
                  </div>
                </Link>
                <div className="py-3 px-2 mt-2">
                  <h3>{node.acf.title}</h3>
                  <Content className="description" content={node.acf.description} />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}