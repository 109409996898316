import React from 'react'
import { graphql, Link } from 'gatsby';

import Layout from 'components/Layout'
import SEO from 'components/SEO';
import HeroTitle from '../components/HeroTitle';
import VodCastsItems from '../components/VodCastItems';


const Vodcasts = ({ data, location }) => {
  const { page, vodcasts } = data
  const url = location.href ? location.href : '';
  const language = page.wpml_current_locale.slice(0, 2);

  return (
    <Layout>
      <SEO yoast={{ meta: page.yoast_meta }} lang={language} pathname={url} />
      <div>
        <HeroTitle title={page.acf.title} fluidImage={page.acf.image.localFile.childImageSharp.fluid} />
        <VodCastsItems fields={vodcasts} description={page.acf.description} />
      </div>
    </Layout>
  )
}

export default Vodcasts

export const query = graphql`
  query VodcastsPageByID($wordpress_id: Int!) {
    page: wordpressPage(
        wordpress_id: { eq: $wordpress_id }
    ) {   
      id
      wordpress_id
      title
      slug
      wpml_current_locale

      acf {
        title
        description
        image {
          localFile {
            childImageSharp {
              fluid(quality: 99, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }

    vodcasts: allWordpressWpVodcasts(sort: {fields: date, order: ASC}) {
      edges {
        node {
          id
          wordpress_id
          slug
          path
          title
          acf {
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 99, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            title
            description
            visible
            length_vodcast
            speakers
            vodcast_url
          }
        }
      }
    }
  }
`